import logo from './logo.gif';
import logo3 from './logo3.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo3} className="App-logo" alt="logo3" />
      </header>
    </div>
  );
}

export default App;
